import React, { ReactElement } from 'react';
import { Link } from 'gatsby';
//import { VideoModule } from 'module/VideoModule';

import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

const HomeContainer = styled.main`
  transition-delay: 100ms;
  transition-duration: 1000ms;
  transition-timing-function: ease-in-out;
  transition-property: all;
  transform: translateY(1rem);
  opacity: ${({ show = false }: { show?: boolean }): string => (show ? `1` : `0`)};

  @media only screen and (max-width: 991px) {
    min-height: calc(100vh - 380px);
  }
`;

const CTAContainer = styled.div`
  margin-bottom: 6rem;
  position: absolute;
  height: 20rem;
  top: 50vh;
  @media only screen and (max-width: 991px) {
    margin-bottom: 1rem;
    padding: 2rem 0 2rem 1rem;
    top: 0vh;
  }

  ${({ light = false }: { light?: boolean }) =>
    light
      ? `
  h3,p,span {
    color: #fff;
  }

  a span {
    color: #000;
  }

  a {
    background-color: #fff !important;
  }

  a:hover {
    background-color: #000 !important;
  }

  a:hover span {
    color: #fff !important;
  }
  `
      : ``}
`;

const mainContent = {
  heroModule: {
    header: 'SPRING SUMMER 22',
    description: 'DELIVERY : February/March 2022',
    cta: `VIEW CATALOG`,
    url: `/collections/ss-22`,
    image: 'https://cdn.shopify.com/s/files/1/0012/4079/3193/files/HOME_PAGE_FINAL_V2.jpg'
  },
  videoModule: {
    videoId: '513470526'
  }
};

const secondaryContent = {
  heroModule: {
    header: 'Pre Spring 22',
    description: 'DELIVERY : November/December 2021',
    cta: `VIEW CATALOG`,
    url: `/collections/ps-22`,
    image:
      'https://cdn.shopify.com/s/files/1/0012/4079/3193/files/R_R9A9262_x2048_9ec2c04e-daeb-42b9-a77b-1a985c632b82.jpg'
  },
  videoModule: {
    videoId: '513470526'
  }
};

const IndexPage = ({ isVip = false }: { isVip?: boolean }): ReactElement => {
  const [ref, inView] = useInView({ triggerOnce: true });

  return (
    <HomeContainer
      ref={ref}
      show={inView}
      className="main-content1 js-focus-hidden"
      id="MainContent"
      role="main"
      tabIndex={-1}>
      <div className="__pf" id="__pf">
        <div data-pf-type="Body" className="sc-pRgDJ fzXWJx pf-68d7de2a">
          <div data-pf-type="Layout" className="pf-1b68d7de">
            <div
              data-container="false"
              className="sc-pZopv bjKdkd pf-32601908"
              data-pf-type="Section"
              style={{ height: 'unset', paddingBottom: '0' }}>
              {!isVip && (
                <>
                  <div className="sc-pzXPE pf-bgv">
                    <img src={mainContent.heroModule.image} />
                  </div>
                  <CTAContainer className="sc-pQQXS iWCqut" light>
                    <div className="sc-qPjXN eYzxUD pf-82533260 pf-r" data-pf-type="Row">
                      <div className="sc-pbYdQ jklmHY pf-c pf-c-xs--12 pf-c-sm--12 pf-c-md--12 pf-c-lg--12">
                        <div className="sc-pkUyL fTyjzF pf-ee698253" data-pf-type="Column">
                          <h3 data-pf-type="Heading" className="sc-oTLFK lcHmGL pf-3fcdc672">
                            <span data-pf-type="Text" className="sc-ptSuy fnmilh pf-406b801b">
                              {mainContent.heroModule.header}
                            </span>
                          </h3>
                          <p data-pf-type="Paragraph2" className="sc-pkvvt cbrLgP pf-b56ad127">
                            <span data-pf-type="Text" className="sc-ptSuy fnmilh pf-c0be322b">
                              {mainContent.heroModule.description}
                            </span>
                          </p>
                          <Link
                            to={mainContent.heroModule.url}
                            target="_self"
                            data-pf-type="Button"
                            className="sc-pZdvY kuwSuz pf-94d2ee69">
                            <span data-pf-type="Text" className="sc-ptSuy fnmilh pf-d6ea47cb">
                              {mainContent.heroModule.cta}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </CTAContainer>
                </>
              )}
            </div>
            <div
              data-container="false"
              className="sc-pZopv bjKdkd pf-32601908"
              data-pf-type="Section"
              style={{ height: 'unset', paddingBottom: '0' }}>
              {!isVip && (
                <>
                  <div className="sc-pzXPE pf-bgv">
                    <img src={secondaryContent.heroModule.image} />
                  </div>
                  <CTAContainer className="sc-pQQXS iWCqut">
                    <div className="sc-qPjXN eYzxUD pf-82533260 pf-r" data-pf-type="Row">
                      <div className="sc-pbYdQ jklmHY pf-c pf-c-xs--12 pf-c-sm--12 pf-c-md--12 pf-c-lg--12">
                        <div className="sc-pkUyL fTyjzF pf-ee698253" data-pf-type="Column">
                          <h3 data-pf-type="Heading" className="sc-oTLFK lcHmGL pf-3fcdc672">
                            <span data-pf-type="Text" className="sc-ptSuy fnmilh pf-406b801b">
                              {secondaryContent.heroModule.header}
                            </span>
                          </h3>
                          <p data-pf-type="Paragraph2" className="sc-pkvvt cbrLgP pf-b56ad127">
                            <span data-pf-type="Text" className="sc-ptSuy fnmilh pf-c0be322b">
                              {secondaryContent.heroModule.description}
                            </span>
                          </p>
                          <Link
                            to={secondaryContent.heroModule.url}
                            target="_self"
                            data-pf-type="Button"
                            className="sc-pZdvY kuwSuz pf-94d2ee69">
                            <span data-pf-type="Text" className="sc-ptSuy fnmilh pf-d6ea47cb">
                              {secondaryContent.heroModule.cta}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </CTAContainer>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </HomeContainer>
  );
};

export default IndexPage;
